@import '../../../../../frontend/styles/tailwind.css';

.two-column-container {
  .themeBlock {
    gap: var(--design-spacing-md)
  }

  // Cant use tailwind because style needs to be scoped with selector outside of the parts html (.columns-2)
  @media (max-width: 1023px) {
    .themeBlock {
      flex-direction: column;
    }
  }
}

.linkAnimation:hover {
  .iconAnimation {
    transform: translateX(calc(var(--design-spacing-xl) - var(--design-spacing-lg)));
    transition: transform 300ms;
  }
}

.iconAnimation {
  transform: translateX(0);
  transition: transform 300ms;
}
